import React from "react";
import { Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Client1Img from "../../../assets/client-1.png";
import Client2Img from "../../../assets/client-2.png";
import Client3Img from "../../../assets/client-3.png";
import SingleReview from "./SingleReview";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const Gallery = () => {
  const style = {
    wrapper: "my-12 w-[90%] md:w-[30%] mx-auto",
    para: " text-2xl md:text-2xl text-center mx-auto",
  };
  return (
    <div className={style.wrapper}>
      <Swiper
        modules={[Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        pagination={{ clickable: true }}
        className="w-[100%]"
        autoplay={true}
      >
        <SwiperSlide className="flex items-center justify-center py-12">
          <SingleReview
          
            name="Ms Gracey's Lashes"
            designation="Local Business Owner"
            message="I actually landed here searching for a web designer in my area but no one wanted to work with urgency I had. They took on my website, worked with my step by step and had me on my way with a brand new e-commerce site for my business in less than 3 weeks. Awesome."
             />
        </SwiperSlide>
        <SwiperSlide className="flex items-center justify-center py-12">
          <SingleReview
        
            name="Anne Marie P"
            designation="Business Manager"
                      message="
I am glad I got introduced to you when I was starting my skin care business. I was able to hand off everything web related and I was able to focus on getting my business. I appreciate the support and recommendations over the years."
          />
        </SwiperSlide>
        <SwiperSlide className="flex items-center justify-center py-12">
          <SingleReview
   
            name="Leslie J"
            designation="Business Owner"
                      message="I handed over my website built with wix to them because the wix developer at the time was not able to manage it anymore. After the initial consult I just knew this is what I needed. I now have a great website that I own  (not on wix, so no more monthly fees) and it is soo beautiful."

          />
              </SwiperSlide>
              <SwiperSlide className="flex items-center justify-center py-12">
                  <SingleReview
                  
                      name="e*****79@gmail.com"
                      designation="Business Owner"
                      message="I love that I am not forced to commit to monthly fees since I do not have regular updates needed and can come to them as and when I need additions/updates."
                      
                  />
              </SwiperSlide>
      </Swiper>
    </div>
  );
};
export default Gallery;
